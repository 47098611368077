(function ($) {
  Drupal.behaviors.gnavDynamicLogoFormatterV1 = {
    attach: function (context) {

      var $siteHeader = $('.js-site-header-formatter-v1', context);
      var $stickyWrapper = $('.js-split-width-sticky__wrapper', context);
      var $logo = $stickyWrapper.find('.js-split-width-sticky__logo');

      if ($logo.length < 1) {
        return;
      }

      $(window).on(
        'load scroll resize',
        _.throttle(function () {
          if ($logo[0].getBoundingClientRect().bottom > 85 && $siteHeader.hasClass('site-header-formatter__visible-logo')) {
            $siteHeader.removeClass('site-header-formatter__visible-logo');
          } else if ($logo[0].getBoundingClientRect().bottom < 85 && !$siteHeader.hasClass('site-header-formatter__visible-logo')) {
            $siteHeader.addClass("site-header-formatter__visible-logo");
          }
        }, 10)
      );
    }
  };
})(jQuery);
